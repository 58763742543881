.field-name-body {
  div[style],
  img {
    max-width: 100% !important;
    max-height: auto !important;
  }
}

/**
 * This is for the big icons with administrator toolbar.
 */
.contextual-links-region .group-media {
  top: -75px;
  right: -100px;
}
