ul.nice-menu-down li {
  margin-left: 10px;
}
ul.nice-menu-down [class^="ico-"],
ul.nice-menu-down [class^="icon-"],
ul.nice-menu-down li span.nolink,
#training-index-button,
.block-ys-elearning-custom [class*="icon-"],
.custom-icon[class*="icon-"] {
  color: $white;
  background: $oof-green !important;
  line-height: 1.7em;

  .orange & {
    background: $oof-orange !important;
  }
  .energy-academy & {
    background: $ea-red !important;
  }

  .uniper & {
    background: $uniper-orange !important;
  }
}

.block-ys-elearning-custom {
  a.media-button {
    font-size: 16px !important;
    width: 26px !important;
    height: 26px !important;

    &:before {
      color: $white;
      padding-top: 0;
      width: 26px !important;
      height: 26px !important;
      font-size: 16px !important;
      margin-left: 0;
    }
  }
}

#block-ys-elearning-custom-training-information,
.block-ys-elearning-custom {
  .field-name-field-kaltura-video {
    display: inline-block;
  }
}

#training-index-button,
.block-ys-elearning-custom .icon-info,
.block-ys-elearning-custom .icon-help {
  display: block;
  text-decoration: none;
  width: 26px !important;
  height: 26px !important;
  overflow: hidden;
  margin: 6px 0px 0px 4px;
}
.block-ys-elearning-custom .icon-help {
  font-size: 16px;
}

#training-index-button:before,
.block-ys-elearning-custom [class*="icon-"]:before {
  position: relative;
  margin-bottom: 25px;
  top: 1px;
}
.block-ys-elearning-custom .icon-info:before,
.block-ys-elearning-custom .icon-help:before {
  position: relative;
  top: 0px;
  margin-bottom: 12px;
}
.block-ys-elearning-custom .icon-info,
.block-ys-elearning-custom .icon-help {
  float: right;
}
#block-ys-elearning-index-training-index-menu {
  padding: 0;
}
ul.nice-menu-down [class^="ico-"]:hover,
ul.nice-menu-down [class^="icon-"]:hover,
ul.nice-menu-down li span.nolink:hover,
ul.nice-menu-down [class^="ico-"]:focus,
ul.nice-menu-down [class^="icon-"]:focus,
ul.nice-menu-down li span.nolink:focus {
  color: $oof-green;
  background: $white;
  border: 1px solid $oof-green;

  .orange & {
    color: $oof-orange;
    border: 1px solid $oof-orange;
  }
  .energy-academy & {
    color: $ea-blue;
    border: 1px solid $ea-blue;
  }
  .uniper & {
    color: $uniper-blue;
    border: 1px solid $uniper-blue;
  }
}
ul.nice-menu ul a {
  background-color: $gray-200;
  color: $black;
  border: 0;
  font-size: 13px;
  font-weight: bold;
}
ul.nice-menu ul li {
  border: 0;
}
ul.nice-menu ul {
  left: -81px;
  background: url(../images/menu_top.png) no-repeat center top transparent;
}
ul.nice-menu a.icon-user {
  padding-top: 1px !important;
  padding-bottom: 3px !important;
}
ul.nice-menu a.icon-book {
  font-size: 14px;
  padding-top: 3px !important;
  padding-bottom: 1px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
}
