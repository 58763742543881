
.block-ys-elearning-index {
  .energy-academy & {
    .menu_arrow {
      background-image: url(../images/energy-academy/arrow.png);
      background-repeat: no-repeat;
      width: 36px;
      height: 27px;
      bottom: 30px;
      left: 348px;
    }

    .tab-wrapper {
      background: $ea-blue;
    }
    .tab-content {
      background: $white;
    }

    #tabs {
      border-bottom: none !important;
      ul {
        background: $white;
      }
      li {
        background: $white;
        border-bottom: 1px solid $ea-blue;
        color: $black;
        border-top: none;
        border-left: none !important;
        border-right: none !important;

        &.ui-state-active {
          border-top: 0;
          border-bottom: 1px solid $ea-red !important;
        }

        a {
          color: $black;
          text-shadow: none;
        }
      }
    }

    .filterform {
      border-bottom: none;
    }

    .filterinput {
      background: #f5f5f5;
      box-shadow: none;
      text-shadow: none;
      color: $black;
    }

    h2 {
      color: $ea-blue;
    }

    .tab-content-list {
      background: $white;
      color: $black;

      ul {
        li {

          a {
            color: $black;

            &:hover,
            &:focus {
              background: $white;
              color: $ea-blue;
            }
          }
        }
      }
    }

    #training-definitions,
    #training-index {
      .tab-content-list {
        li {
          &.first {
            h2 {
              border-top: none;
              border-bottom: 1px solid $gray-900;
            }
          }

          dfn,
          a {
            border-top: none;
            border-bottom: 1px solid $gray-900;
          }
        }
      }
    }
  }
}
