@font-face {
  font-family: 'Nettoweb';
  src: url('fonts/nettoweb/nettoweb.eot?') format('eot'),
  url('fonts/nettoweb/nettoweb.woff') format('woff'),
  url('fonts/nettoweb/nettoweb.ttf')  format('truetype'),
  url('fonts/nettoweb/nettoweb.svg#nettoweb') format('svg');
}

@font-face {
  font-family: 'NettowebBold';
  src: url('fonts/nettoweb/nettoweb_bold.eot?') format('eot'),
  url('fonts/nettoweb/nettoweb_bold.woff') format('woff'),
  url('fonts/nettoweb/nettoweb_bold.ttf')  format('truetype'),
  url('fonts/nettoweb/nettoweb_bold.svg#nettoweb_bold') format('svg');
}

body {
  background: url(../images/header_bg.png) repeat-x;
  font-size: 15px;
  font-family: helvetica;
  background-color: white;

  &.energy-academy {
    background: none;
  }

  &.uniper {
    background: none;
  }
}
body.fancybox {
  height: auto;
}
.squeeze {
  width: 90%;
}
h1,h2,h3,h4,h5,h6 {
  font-family: Nettoweb;
}
h1 {
  font-size: 1.7em;
}

.site-name h3 {
  font-size: 24px;
}
.text-accent {
  color: $oof-dark-green;
  font-style: italic;

  .orange & {
    color: $oof-dark-orange;
  }

  .energy-academy & {
    color: $ea-blue;
  }

  .uniper & {
    color: $uniper-blue;
  }
}
.container-white {
  .energy-academy & {
    border-bottom: 2px solid $ea-blue;
  }

  .uniper & {
    background: $uniper-blue;
    border-bottom: 2px solid $uniper-blue;

    color: white;
  }
}
.container-header {
  padding-top: 60px;
  border-bottom: 2px solid $oof-green;

  .orange & {
    border-bottom: 2px solid $oof-orange;
  }

  .energy-academy & {
    border-bottom: none;
  }

  .uniper & {
    border-bottom: none;
  }
}

#header {
  margin: 0;
}
#logo-container {
  width: 180px;
  float: left;

  .energy-academy & {
    width: 240px;
  }

  .uniper & {
    text-align: center;

    img {
      height: 50px;
    }
  }
}
#main-wrapper {
  padding: 50px;
  padding-bottom: 0;
}
#main-wrapper .block-system {
  padding: 10px 50px 0;
}

.collapsible-wrapper .toggle-collapse {
  position: static;
  float: left;
  margin: 5px 5px 0 0;
}
#user-profile-form .collapsible-wrapper .toggle-collapse {
  margin: 0 5px 0 0;
}
#user-profile-form fieldset {
  padding: 0;
  margin-bottom: 1.3em;
  height: auto;
}
.collapsible-wrapper .fieldset-title {
  background: url(../images/expanded.png) no-repeat !important;

  .orange & {
    background: url(../images/orange/expanded.png) no-repeat !important;
  }
  .energy-academy & {
    background: url(../images/energy-academy/expanded.png) no-repeat !important;
  }
  .uniper & {
    background: url(../images/uniper/expanded.png) no-repeat !important;
  }
}
.collapsible-wrapper.collapsed .fieldset-title {
  background: url(../images/collapsed.png) no-repeat !important;

  .orange & {
    background: url(../images/orange/collapsed.png) no-repeat !important;
  }
  .energy-academy & {
    background: url(../images/energy-academy/collapsed.png) no-repeat !important;
  }
  .uniper & {
    background: url(../images/uniper/collapsed.png) no-repeat !important;
  }
}
#block-ys-elearning-custom-register-block,
.collapsible-wrapper {
  border-bottom: 1px dotted $oof-green;

  .orange & {
    border-bottom: 1px dotted $oof-orange;
  }

  .energy-academy & {
    color: $ea-blue;
  }
  .uniper & {
    color: $uniper-blue;
  }
}

.front #block-system-main h2,
#block-ys-elearning-custom-register-block h2 {
  color: $oof-green;

  .orange & {
    color: $oof-orange;
  }

  .energy-academy & {
    color: $ea-blue;
  }

  .uniper & {
    color: $uniper-blue;
  }
}
#user-register-form label {
  color: $black;
}

.group-media .field,
.custom-button,
div.info-icon-wrapper {
  border: 1px solid $oof-green;

  .orange & {
    border: 1px solid $oof-orange;
  }

  .energy-academy & {
    border: 1px solid $ea-blue;
  }

  .uniper & {
    border: 1px solid $uniper-blue;
  }
}
a.media-button,
span.media-button,
a.media-button:before,
span.media-button:before,
div.info-icon-wrapper a,
div.info-icon-wrapper a:before {
  color: $oof-green;

  .orange & {
    color: $oof-orange;
  }

  .energy-academy & {
    color: $ea-blue;
  }

  .uniper & {
    color: $uniper-blue;
  }
}


.js-pos-hidden,
.hidden-flash {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.button-inline {
  background-color: $oof-green;

  .orange & {
    background-color: $oof-orange;
  }

  .energy-academy & {
    background-color: $ea-blue;
  }

  .uniper & {
    background-color: $uniper-blue;
  }
}
.button-inline:hover,
.button-inline:focus {
  background-color: $oof-dark-green;

  .orange & {
    background-color: $oof-dark-orange;
  }

  .energy-academy & {
    background-color: $ea-light-blue;
  }

  .uniper & {
    background-color: darken($uniper-blue, 20%);
  }
}

.hover-item:hover,
.hover-item:focus,
.hover-item.hover {
  border: 2px solid $oof-dark-green;

  .orange & {
    border: 2px solid $oof-dark-orange;
  }

  .energy-academy & {
    border: 2px solid $ea-light-blue;
  }

  .uniper & {
    border: 2px solid darken($uniper-blue, 20%);
  }
}

.menu-item-block {
  display: inline-block;
  padding: 10px;
  height: 204px;
  width: 630px;
  margin: 0 20px 20px 0;
  border: 2px solid $oof-dark-green;
  float: left;

  .orange & {
    border: 2px solid $oof-dark-orange;
  }

  .energy-academy & {
    border: 2px solid $ea-light-blue;
  }

  .uniper & {
    border: 2px solid $uniper-blue;
  }
}
.image-index-200 {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  float: left;
}
.index-hide-text {
  width: 400px;
  height: 140px;
  overflow: hidden;
  overflow-y: scroll;
}

#ys-rest-certificate-test-page .form-item {
  max-width: 400px;
}

.company-wrapper {
  margin-bottom: 30px;
  clear: left;
}
.company-wrapper a.email-address {
  display: block;
  float: left;
  margin-right: 30px;
  border: 1px solid $oof-orange;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}
.company-wrapper a.email-address:hover,
.company-wrapper a.email-address.active {
  border: 1px solid $black;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}
.company-wrapper img[style] {
  width: 100px !important;
  height: 100px !important;
}
.not-checked {
  opacity: 0.2;
}

#flexslider-1 li {
  list-style: none;
}

.answer-correct {
  color: $oof-green;
}
.answer-failed {
  color: $oof-red;
}

/* ================================ Media Queries =========================== */
/**
 * Tablets
 */
@media screen and (max-width: 1000px) {
  .squeeze {
    width: 746px;
  }
  .system-main .group-media {
    top: 0 !important;
  }
  h1.title {
    width: 60%;
  }

  .system-main .field-group-tabs-wrapper {
    margin-top: -65px !important;
  }
  .field-name-body div[style],
  .field-name-body img {
    max-width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 767px) {
  .squeeze {
    max-width: 90%;
    width: auto;
  }
  #main-wrapper .block-system {
    padding: 0;
  }
  .container-header {
    border-bottom: 0;
  }
  .container-white {
    border-bottom: 2px solid $oof-green;
    margin-bottom: 40px;

    .orange & {
      border-bottom: 2px solid $oof-orange;
    }

    .energy-academy & {
      border-bottom: 2px solid $ea-blue;
    }

    .uniper & {
      border-bottom: 2px solid $uniper-blue;
    }
  }
  #header {
    position: inherit;
    margin: 0px;
    margin-right: 20px;
  }
  #logo-container {
    line-height: 0;
    margin-bottom: 10px;
    height: auto;
  }
  h1.title {
    width: 100% !important;
  }
  .site-name {
    width: auto;
    margin-right: 50px;
  }
  .site-name h3 {
    margin: 0;
    margin-left: 5px;
  }
  .block-system .field-group-tabs-wrapper {
    margin-top: 0 !important;
    float: none;
    position: inherit;
    margin-bottom: 20px;
    display: block;
  }
  .block-system .group-media {
    position: inherit !important;
    margin: 0;
    top: 0 !important;
  }
  .group-media .field, div.info-icon-wrapper,
  .custom-button {
    float: left;
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
  .group-media .field {
    margin-bottom: 10px !important;
  }
  #main-wrapper {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .system-main {
    padding: 0 !important;
  }
  .field-name-field-video,
  .field-name-field-information-area {
    bottom: 0;
  }
  .field-name-body div[style],
  .field-name-body img {
    max-width: 100% !important;
    height: auto !important;
  }
  .course-navigation-link {
    bottom: 0px !important;
    top: auto;
    height: 40px;
    float:none;
    margin: 0;
    width: 50%;
    position: absolute;
  }
  .next {
    right: 0px;
    background-position: 100%,0%;
  }
  .previous {
    left: 0px;
    background-position: 0 0;
  }
  .node .content {
    position: relative;
    padding-bottom: 30px;
  }
}
