.h5p-iframe-wrapper {
  max-width: 1024px;
  height: auto;
  margin: 0 auto;
}

.h5p-course-presentation {
  .h5p-progressbar {
    .h5p-progressbar-part-show {
      background: $oof-green;

      .orange & {
        background: $oof-orange;
      }

      .energy-academy & {
        background: $ea-blue;
      }

      .uniper & {
        background: $uniper-blue;
      }
    }
  }

  .h5p-element-button {
    background: $oof-green;
    border: none;

    .orange & {
      background: $oof-orange;
    }

    .energy-academy & {
      background: $ea-blue;
    }

    .uniper & {
      background: $uniper-blue;
    }
  }

  .h5p-presentation-wrapper {
    background: $white;
  }
}

.h5p-question-explanation-correct {
  color: $oof-green;
}

.h5p-question-explanation-wrong {
  color: $oof-red;

  .energy-academy & {
    color: $ea-red;
  }
}

.h5p-summary {
  .summary-container li:before {
    color: $oof-green;
  }

  .h5p-panel:not(.panel-disabled) .summary-claim-unclicked:hover {
    background: #ececec;
  }

  li.summary-failed > p,
  li.summary-failed {
    color: $oof-red;
    border: 0.1em solid lighten($oof-red, 40%);
    background: lighten($oof-red, 40%);

    .energy-academy & {
      background: $ea-red;
      border: 0.1em solid lighten($ea-red, 40%);
      background: lighten($ea-red, 40%);
    }
  }

  li.summary-failed:before {
    color: $oof-red;

    .energy-academy & {
      color: $ea-red;
    }
  }

  .summary-score {
    background: #f7d0d0;
    background: lighten($oof-red, 40%);

    .energy-academy & {
      background: lighten($ea-red, 40%);
    }
  }

  .summary-score:before {
    color: $oof-red;

    .energy-academy & {
      color: $ea-red;
    }
  }

  .summary-progress-numeric {
    background: lighten($oof-green, 40%);
  }

  .summary-progress-numeric:before {
    color: $oof-green;
  }
}

.h5peditor .ui-dialog .h5p-joubelui-button,
.h5peditor .h5p-joubelui-button,
.h5p-joubelui-button {
  background: $oof-green;
  color: #ffffff;

  &:hover,
  &:focus {
    background: darken($oof-green, 20%);
  }

  .orange & {
    background: $oof-orange;

    &:hover,
    &:focus {
      background: darken($oof-orange, 20%);
    }
  }

  .energy-academy & {
    background: $ea-blue;

    &:hover,
    &:focus {
      background: darken($ea-blue, 20%);
    }
  }

  .uniper & {
    background: $uniper-blue;

    &:hover,
    &:focus {
      background: darken($ea-blue, 20%);
    }
  }
}

.h5p-interactive-video {
  div {
    > .h5p-interaction-button {
      background-color: $oof-green;

      &:hover,
      &:focus {
        background-color: darken($oof-green, 20%);
      }

      .orange & {
        background-color: $oof-orange;

        &:hover,
        &:focus {
          background-color: darken($oof-orange, 20%);
        }
      }

      .energy-academy & {
        background-color: $ea-blue;

        &:hover,
        &:focus {
          background-color: darken($ea-blue, 20%);
        }
      }

      .uniper & {
        background: $uniper-blue;

        &:hover,
        &:focus {
          background: darken($ea-blue, 20%);
        }
      }
    }
  }

  .h5p-text-interaction,
  .h5p-table-interaction,
  .h5p-link-interaction,
  .h5p-image-interaction {
    > .h5p-interaction-button {
      background-color: $oof-green;

      &:hover,
      &:focus {
        background-color: darken($oof-green, 20%);
      }

      .orange & {
        background-color: $oof-orange;

        &:hover,
        &:focus {
          background-color: darken($oof-orange, 20%);
        }
      }

      .energy-academy & {
        background-color: $ea-blue;

        &:hover,
        &:focus {
          background-color: darken($ea-blue, 20%);
        }
      }

      .uniper & {
        background: $uniper-blue;

        &:hover,
        &:focus {
          background: darken($ea-blue, 20%);
        }
      }
    }
  }
}

.h5p-single-choice-set {
  .h5p-joubelui-progressbar-background {
    background-color: $oof-green;

    .orange & {
      background-color: $oof-orange;
    }

    .energy-academy & {
      background-color: $ea-blue;
    }

    .uniper & {
      background: $uniper-blue;

      &:hover,
      &:focus {
        background: darken($ea-blue, 20%);
      }
    }
  }

  .h5p-sc-solution-view {
    .h5p-sc-close-solution-view {
      background-color: $oof-green;

      &:hover,
      &:focus {
        background-color: darken($oof-green, 20%);
      }

      .orange & {
        background-color: $oof-orange;

        &:hover,
        &:focus {
          background-color: darken($oof-orange, 20%);
        }
      }

      .energy-academy & {
        background-color: $ea-blue;

        &:hover,
        &:focus {
          background-color: darken($ea-blue, 20%);
        }
      }

      .uniper & {
        background: $uniper-blue;

        &:hover,
        &:focus {
          background: darken($ea-blue, 20%);
        }
      }
    }
  }
}

.h5p-question-feedback  {
  color: $oof-green;

  .orange & {
    color: $oof-orange;
  }

  .energy-academy & {
    color: $ea-blue;
  }

  .uniper & {
    background: $uniper-blue;
  }
}

.joubel-simple-rounded-button {
  background-color: $oof-green;

  &:hover,
  &:focus {
    background-color: darken($oof-green, 20%);
  }

  .orange & {
    background-color: $oof-orange;

    &:hover,
    &:focus {
      background-color: #darken($oof-orange, 20%);
    }
  }

  .energy-academy & {
    background-color: $ea-blue;

    &:hover,
    &:focus {
      background-color: #darken($ea-blue, 20%);
    }
  }

  .uniper & {
    background: $uniper-blue;

    &:hover,
    &:focus {
      background-color: #darken($uniper-blue, 20%);
    }
  }

  &:active {
    position: relative;
    background-color: $ea-blue;

    -webkit-box-shadow: inset 0 4px 0px $ea-blue;
    -moz-box-shadow: inset 0 4px 0px $ea-blue;
    box-shadow: inset 0 4px 0px $ea-blue;
  }
}

.h5p-joubelui-score-bar-progress {
  background: $oof-green;
}
