
.form-button-wrapper input, .form-button-wrapper .button-link {
  background-color: $black;
  color: white;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  line-height: 100%;
  padding: 10px 12px;
}
.form-button-wrapper input.disabled {
  background-color: $gray-200;
}

.load-image {
  background-image:
          url("../images/radio.png"),
          url("../images/radio_disabled.png"),
          url("../images/checkbox.png"),
          url("../images/checkbox_disabled.png");
  display: none;

  .orange & {
    background-image:
            url("../images/orange/radio.png"),
            url("../images/radio_disabled.png"),
            url("../images/orange/checkbox.png"),
            url("../images/checkbox_disabled.png");
  }
  .energy-academy & {
    background-image:
            url("../images/energy-academy/radio.png"),
            url("../images/radio_disabled.png"),
            url("../images/energy-academy/checkbox.png"),
            url("../images/checkbox_disabled.png");
  }
  .uniper & {
    background-image:
            url("../images/uniper/radio.png"),
            url("../images/radio_disabled.png"),
            url("../images/uniper/checkbox.png"),
            url("../images/checkbox_disabled.png");
  }
}
#foo:checked,
input.form-checkbox + label::before,
input.form-radio + label::before,
form table input.form-checkbox,
form table input.form-radio {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/radio.png");
  background-repeat: no-repeat;
  background-position: 0 0;

  .orange & {
    background-image: url("../images/orange/radio.png");
  }
  .energy-academy & {
    background-image: url("../images/energy-academy/radio.png");
  }
  .uniper & {
    background-image: url("../images/uniper/radio.png");
  }
}
#foo:checked,
input.form-checkbox:checked + label::before,
input.form-radio:checked + label::before {
  background-position: 0 -20px;
}
#foo:checked,
input.form-checkbox.disabled + label::before,
form table input.form-checkbox.disabled {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/checkbox_disabled.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}

#foo:checked,
input.form-checkbox + label::before,
form table input.form-checkbox {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/checkbox.png");
  background-repeat: no-repeat;
  background-position: 0 0;

  .orange & {
    background-image: url("../images/orange/checkbox.png");
  }
  .energy-academy & {
    background-image: url("../images/energy-academy/checkbox.png");
  }
  .uniper & {
    background-image: url("../images/uniper/checkbox.png");
  }
}

#foo:checked,
input.form-checkbox:checked + label::before,
input.form-radio:checked + label::before {
  background-position: 0 -20px;
}
#foo:checked,
input.form-radio.disabled + label::before,
form table input.form-radio.disabled {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/radio_disabled.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}
#foo:checked,
input.form-checkbox.disabled:checked + label::before,
input.form-radio.disabled:checked + label::before {
  background-position: 0 -20px;
}

.form-type-checkbox .feedback {
  margin-left: 76.7px;
  font-size: 14px;
  position: relative;
  padding-right: 60px;
}
.form-type-checkbox .feedback .media-button {
  position: absolute;
  right: 0;
  top: 0;
}
.form-type-checkbox .feedback .icon-videocam {
  display: block;
  padding: 10px 5px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 30px;
  color: $oof-green;
  text-decoration: none;
  border: 1px solid $oof-green;

  .orange & {
    color: $oof-orange;
    border: 1px solid $oof-orange;
  }

  .energy-academy & {
    color: $ea-blue;
    border: 1px solid $ea-blue;
  }

  .uniper & {
    color: $uniper-blue;
    border: 1px solid $uniper-blue;
  }
}
.form-type-checkbox .feedback div.need_to_know a:after {
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  background: url(../images/need_to-know.png) no-repeat;
  content: ' ';
  margin-top: -2px;
  margin-bottom: -20px;
  right: -8px;
  top: -3px;
}
.form-type-checkbox .feedback .icon-videocam:hover {
  color: $oof-red;
  border-color: $oof-red;
}
.form-type-checkbox .feedback .fancybox-opener:before {
  text-indent: 0px;
}
.form-item-question-answer label:first-child {
  font-family: 'Nettoweb';
  font-size: 24px;
  margin-bottom: 45px;
}
.form-item-question-answer .form-checkboxes {
  padding: 0 20px;
}
.form-item-question-answer .form-checkboxes label {
  font-size: 18px;
  font-family: 'Nettoweb';
}
.form-item-question-answer .form-type-radio label,
.form-item-question-answer .form-checkboxes label {
  max-width: 80%;
}

#feedback-message {
  color: $oof-red;
}
