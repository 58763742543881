@font-face {
  font-family: 'Nettoweb';
  src: url("fonts/nettoweb/nettoweb.eot?") format("eot"), url("fonts/nettoweb/nettoweb.woff") format("woff"), url("fonts/nettoweb/nettoweb.ttf") format("truetype"), url("fonts/nettoweb/nettoweb.svg#nettoweb") format("svg"); }

@font-face {
  font-family: 'NettowebBold';
  src: url("fonts/nettoweb/nettoweb_bold.eot?") format("eot"), url("fonts/nettoweb/nettoweb_bold.woff") format("woff"), url("fonts/nettoweb/nettoweb_bold.ttf") format("truetype"), url("fonts/nettoweb/nettoweb_bold.svg#nettoweb_bold") format("svg"); }

body {
  background: url(../images/header_bg.png) repeat-x;
  font-size: 15px;
  font-family: helvetica;
  background-color: white; }
  body.energy-academy {
    background: none; }
  body.uniper {
    background: none; }

body.fancybox {
  height: auto; }

.squeeze {
  width: 90%; }

h1, h2, h3, h4, h5, h6 {
  font-family: Nettoweb; }

h1 {
  font-size: 1.7em; }

.site-name h3 {
  font-size: 24px; }

.text-accent {
  color: #52822e;
  font-style: italic; }
  .orange .text-accent {
    color: #ad440b; }
  .energy-academy .text-accent {
    color: #364A9A; }
  .uniper .text-accent {
    color: #0078dc; }

.energy-academy .container-white {
  border-bottom: 2px solid #364A9A; }

.uniper .container-white {
  background: #0078dc;
  border-bottom: 2px solid #0078dc;
  color: white; }

.container-header {
  padding-top: 60px;
  border-bottom: 2px solid #72b639; }
  .orange .container-header {
    border-bottom: 2px solid #f16f29; }
  .energy-academy .container-header {
    border-bottom: none; }
  .uniper .container-header {
    border-bottom: none; }

#header {
  margin: 0; }

#logo-container {
  width: 180px;
  float: left; }
  .energy-academy #logo-container {
    width: 240px; }
  .uniper #logo-container {
    text-align: center; }
    .uniper #logo-container img {
      height: 50px; }

#main-wrapper {
  padding: 50px;
  padding-bottom: 0; }

#main-wrapper .block-system {
  padding: 10px 50px 0; }

.collapsible-wrapper .toggle-collapse {
  position: static;
  float: left;
  margin: 5px 5px 0 0; }

#user-profile-form .collapsible-wrapper .toggle-collapse {
  margin: 0 5px 0 0; }

#user-profile-form fieldset {
  padding: 0;
  margin-bottom: 1.3em;
  height: auto; }

.collapsible-wrapper .fieldset-title {
  background: url(../images/expanded.png) no-repeat !important; }
  .orange .collapsible-wrapper .fieldset-title {
    background: url(../images/orange/expanded.png) no-repeat !important; }
  .energy-academy .collapsible-wrapper .fieldset-title {
    background: url(../images/energy-academy/expanded.png) no-repeat !important; }
  .uniper .collapsible-wrapper .fieldset-title {
    background: url(../images/uniper/expanded.png) no-repeat !important; }

.collapsible-wrapper.collapsed .fieldset-title {
  background: url(../images/collapsed.png) no-repeat !important; }
  .orange .collapsible-wrapper.collapsed .fieldset-title {
    background: url(../images/orange/collapsed.png) no-repeat !important; }
  .energy-academy .collapsible-wrapper.collapsed .fieldset-title {
    background: url(../images/energy-academy/collapsed.png) no-repeat !important; }
  .uniper .collapsible-wrapper.collapsed .fieldset-title {
    background: url(../images/uniper/collapsed.png) no-repeat !important; }

#block-ys-elearning-custom-register-block,
.collapsible-wrapper {
  border-bottom: 1px dotted #72b639; }
  .orange #block-ys-elearning-custom-register-block, .orange
  .collapsible-wrapper {
    border-bottom: 1px dotted #f16f29; }
  .energy-academy #block-ys-elearning-custom-register-block, .energy-academy
  .collapsible-wrapper {
    color: #364A9A; }
  .uniper #block-ys-elearning-custom-register-block, .uniper
  .collapsible-wrapper {
    color: #0078dc; }

.front #block-system-main h2,
#block-ys-elearning-custom-register-block h2 {
  color: #72b639; }
  .orange .front #block-system-main h2, .orange
  #block-ys-elearning-custom-register-block h2 {
    color: #f16f29; }
  .energy-academy .front #block-system-main h2, .energy-academy
  #block-ys-elearning-custom-register-block h2 {
    color: #364A9A; }
  .uniper .front #block-system-main h2, .uniper
  #block-ys-elearning-custom-register-block h2 {
    color: #0078dc; }

#user-register-form label {
  color: #000; }

.group-media .field,
.custom-button,
div.info-icon-wrapper {
  border: 1px solid #72b639; }
  .orange .group-media .field, .orange
  .custom-button, .orange
  div.info-icon-wrapper {
    border: 1px solid #f16f29; }
  .energy-academy .group-media .field, .energy-academy
  .custom-button, .energy-academy
  div.info-icon-wrapper {
    border: 1px solid #364A9A; }
  .uniper .group-media .field, .uniper
  .custom-button, .uniper
  div.info-icon-wrapper {
    border: 1px solid #0078dc; }

a.media-button,
span.media-button,
a.media-button:before,
span.media-button:before,
div.info-icon-wrapper a,
div.info-icon-wrapper a:before {
  color: #72b639; }
  .orange a.media-button, .orange
  span.media-button, .orange
  a.media-button:before, .orange
  span.media-button:before, .orange
  div.info-icon-wrapper a, .orange
  div.info-icon-wrapper a:before {
    color: #f16f29; }
  .energy-academy a.media-button, .energy-academy
  span.media-button, .energy-academy
  a.media-button:before, .energy-academy
  span.media-button:before, .energy-academy
  div.info-icon-wrapper a, .energy-academy
  div.info-icon-wrapper a:before {
    color: #364A9A; }
  .uniper a.media-button, .uniper
  span.media-button, .uniper
  a.media-button:before, .uniper
  span.media-button:before, .uniper
  div.info-icon-wrapper a, .uniper
  div.info-icon-wrapper a:before {
    color: #0078dc; }

.js-pos-hidden,
.hidden-flash {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.button-inline {
  background-color: #72b639; }
  .orange .button-inline {
    background-color: #f16f29; }
  .energy-academy .button-inline {
    background-color: #364A9A; }
  .uniper .button-inline {
    background-color: #0078dc; }

.button-inline:hover,
.button-inline:focus {
  background-color: #52822e; }
  .orange .button-inline:hover, .orange
  .button-inline:focus {
    background-color: #ad440b; }
  .energy-academy .button-inline:hover, .energy-academy
  .button-inline:focus {
    background-color: #6C85C2; }
  .uniper .button-inline:hover, .uniper
  .button-inline:focus {
    background-color: #004076; }

.hover-item:hover,
.hover-item:focus,
.hover-item.hover {
  border: 2px solid #52822e; }
  .orange .hover-item:hover, .orange
  .hover-item:focus, .orange
  .hover-item.hover {
    border: 2px solid #ad440b; }
  .energy-academy .hover-item:hover, .energy-academy
  .hover-item:focus, .energy-academy
  .hover-item.hover {
    border: 2px solid #6C85C2; }
  .uniper .hover-item:hover, .uniper
  .hover-item:focus, .uniper
  .hover-item.hover {
    border: 2px solid #004076; }

.menu-item-block {
  display: inline-block;
  padding: 10px;
  height: 204px;
  width: 630px;
  margin: 0 20px 20px 0;
  border: 2px solid #52822e;
  float: left; }
  .orange .menu-item-block {
    border: 2px solid #ad440b; }
  .energy-academy .menu-item-block {
    border: 2px solid #6C85C2; }
  .uniper .menu-item-block {
    border: 2px solid #0078dc; }

.image-index-200 {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  float: left; }

.index-hide-text {
  width: 400px;
  height: 140px;
  overflow: hidden;
  overflow-y: scroll; }

#ys-rest-certificate-test-page .form-item {
  max-width: 400px; }

.company-wrapper {
  margin-bottom: 30px;
  clear: left; }

.company-wrapper a.email-address {
  display: block;
  float: left;
  margin-right: 30px;
  border: 1px solid #f16f29;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */ }

.company-wrapper a.email-address:hover,
.company-wrapper a.email-address.active {
  border: 1px solid #000;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%); }

.company-wrapper img[style] {
  width: 100px !important;
  height: 100px !important; }

.not-checked {
  opacity: 0.2; }

#flexslider-1 li {
  list-style: none; }

.answer-correct {
  color: #72b639; }

.answer-failed {
  color: #EF2751; }

/* ================================ Media Queries =========================== */
/**
 * Tablets
 */
@media screen and (max-width: 1000px) {
  .squeeze {
    width: 746px; }
  .system-main .group-media {
    top: 0 !important; }
  h1.title {
    width: 60%; }
  .system-main .field-group-tabs-wrapper {
    margin-top: -65px !important; }
  .field-name-body div[style],
  .field-name-body img {
    max-width: 100% !important;
    height: auto !important; } }

@media only screen and (max-width: 767px) {
  .squeeze {
    max-width: 90%;
    width: auto; }
  #main-wrapper .block-system {
    padding: 0; }
  .container-header {
    border-bottom: 0; }
  .container-white {
    border-bottom: 2px solid #72b639;
    margin-bottom: 40px; }
    .orange .container-white {
      border-bottom: 2px solid #f16f29; }
    .energy-academy .container-white {
      border-bottom: 2px solid #364A9A; }
    .uniper .container-white {
      border-bottom: 2px solid #0078dc; }
  #header {
    position: inherit;
    margin: 0px;
    margin-right: 20px; }
  #logo-container {
    line-height: 0;
    margin-bottom: 10px;
    height: auto; }
  h1.title {
    width: 100% !important; }
  .site-name {
    width: auto;
    margin-right: 50px; }
  .site-name h3 {
    margin: 0;
    margin-left: 5px; }
  .block-system .field-group-tabs-wrapper {
    margin-top: 0 !important;
    float: none;
    position: inherit;
    margin-bottom: 20px;
    display: block; }
  .block-system .group-media {
    position: inherit !important;
    margin: 0;
    top: 0 !important; }
  .group-media .field, div.info-icon-wrapper,
  .custom-button {
    float: left;
    margin-left: 0 !important;
    margin-right: 10px !important; }
  .group-media .field {
    margin-bottom: 10px !important; }
  #main-wrapper {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px; }
  .system-main {
    padding: 0 !important; }
  .field-name-field-video,
  .field-name-field-information-area {
    bottom: 0; }
  .field-name-body div[style],
  .field-name-body img {
    max-width: 100% !important;
    height: auto !important; }
  .course-navigation-link {
    bottom: 0px !important;
    top: auto;
    height: 40px;
    float: none;
    margin: 0;
    width: 50%;
    position: absolute; }
  .next {
    right: 0px;
    background-position: 100%,0%; }
  .previous {
    left: 0px;
    background-position: 0 0; }
  .node .content {
    position: relative;
    padding-bottom: 30px; } }

.field-name-body div[style],
.field-name-body img {
  max-width: 100% !important;
  max-height: auto !important; }

/**
 * This is for the big icons with administrator toolbar.
 */
.contextual-links-region .group-media {
  top: -75px;
  right: -100px; }

.h5p-iframe-wrapper {
  max-width: 1024px;
  height: auto;
  margin: 0 auto; }

.h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
  background: #72b639; }
  .orange .h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
    background: #f16f29; }
  .energy-academy .h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
    background: #364A9A; }
  .uniper .h5p-course-presentation .h5p-progressbar .h5p-progressbar-part-show {
    background: #0078dc; }

.h5p-course-presentation .h5p-element-button {
  background: #72b639;
  border: none; }
  .orange .h5p-course-presentation .h5p-element-button {
    background: #f16f29; }
  .energy-academy .h5p-course-presentation .h5p-element-button {
    background: #364A9A; }
  .uniper .h5p-course-presentation .h5p-element-button {
    background: #0078dc; }

.h5p-course-presentation .h5p-presentation-wrapper {
  background: #fff; }

.h5p-question-explanation-correct {
  color: #72b639; }

.h5p-question-explanation-wrong {
  color: #EF2751; }
  .energy-academy .h5p-question-explanation-wrong {
    color: #DF2127; }

.h5p-summary .summary-container li:before {
  color: #72b639; }

.h5p-summary .h5p-panel:not(.panel-disabled) .summary-claim-unclicked:hover {
  background: #ececec; }

.h5p-summary li.summary-failed > p,
.h5p-summary li.summary-failed {
  color: #EF2751;
  border: 0.1em solid #fde5ea;
  background: #fde5ea; }
  .energy-academy .h5p-summary li.summary-failed > p, .energy-academy
  .h5p-summary li.summary-failed {
    background: #DF2127;
    border: 0.1em solid #f9d3d4;
    background: #f9d3d4; }

.h5p-summary li.summary-failed:before {
  color: #EF2751; }
  .energy-academy .h5p-summary li.summary-failed:before {
    color: #DF2127; }

.h5p-summary .summary-score {
  background: #f7d0d0;
  background: #fde5ea; }
  .energy-academy .h5p-summary .summary-score {
    background: #f9d3d4; }

.h5p-summary .summary-score:before {
  color: #EF2751; }
  .energy-academy .h5p-summary .summary-score:before {
    color: #DF2127; }

.h5p-summary .summary-progress-numeric {
  background: #dcefcc; }

.h5p-summary .summary-progress-numeric:before {
  color: #72b639; }

.h5peditor .ui-dialog .h5p-joubelui-button,
.h5peditor .h5p-joubelui-button,
.h5p-joubelui-button {
  background: #72b639;
  color: #ffffff; }
  .h5peditor .ui-dialog .h5p-joubelui-button:hover, .h5peditor .ui-dialog .h5p-joubelui-button:focus,
  .h5peditor .h5p-joubelui-button:hover,
  .h5peditor .h5p-joubelui-button:focus,
  .h5p-joubelui-button:hover,
  .h5p-joubelui-button:focus {
    background: #416821; }
  .orange .h5peditor .ui-dialog .h5p-joubelui-button, .orange
  .h5peditor .h5p-joubelui-button, .orange
  .h5p-joubelui-button {
    background: #f16f29; }
    .orange .h5peditor .ui-dialog .h5p-joubelui-button:hover, .orange .h5peditor .ui-dialog .h5p-joubelui-button:focus, .orange
    .h5peditor .h5p-joubelui-button:hover, .orange
    .h5peditor .h5p-joubelui-button:focus, .orange
    .h5p-joubelui-button:hover, .orange
    .h5p-joubelui-button:focus {
      background: #a9420b; }
  .energy-academy .h5peditor .ui-dialog .h5p-joubelui-button, .energy-academy
  .h5peditor .h5p-joubelui-button, .energy-academy
  .h5p-joubelui-button {
    background: #364A9A; }
    .energy-academy .h5peditor .ui-dialog .h5p-joubelui-button:hover, .energy-academy .h5peditor .ui-dialog .h5p-joubelui-button:focus, .energy-academy
    .h5peditor .h5p-joubelui-button:hover, .energy-academy
    .h5peditor .h5p-joubelui-button:focus, .energy-academy
    .h5p-joubelui-button:hover, .energy-academy
    .h5p-joubelui-button:focus {
      background: #1c264e; }
  .uniper .h5peditor .ui-dialog .h5p-joubelui-button, .uniper
  .h5peditor .h5p-joubelui-button, .uniper
  .h5p-joubelui-button {
    background: #0078dc; }
    .uniper .h5peditor .ui-dialog .h5p-joubelui-button:hover, .uniper .h5peditor .ui-dialog .h5p-joubelui-button:focus, .uniper
    .h5peditor .h5p-joubelui-button:hover, .uniper
    .h5peditor .h5p-joubelui-button:focus, .uniper
    .h5p-joubelui-button:hover, .uniper
    .h5p-joubelui-button:focus {
      background: #1c264e; }

.h5p-interactive-video div > .h5p-interaction-button {
  background-color: #72b639; }
  .h5p-interactive-video div > .h5p-interaction-button:hover, .h5p-interactive-video div > .h5p-interaction-button:focus {
    background-color: #416821; }
  .orange .h5p-interactive-video div > .h5p-interaction-button {
    background-color: #f16f29; }
    .orange .h5p-interactive-video div > .h5p-interaction-button:hover, .orange .h5p-interactive-video div > .h5p-interaction-button:focus {
      background-color: #a9420b; }
  .energy-academy .h5p-interactive-video div > .h5p-interaction-button {
    background-color: #364A9A; }
    .energy-academy .h5p-interactive-video div > .h5p-interaction-button:hover, .energy-academy .h5p-interactive-video div > .h5p-interaction-button:focus {
      background-color: #1c264e; }
  .uniper .h5p-interactive-video div > .h5p-interaction-button {
    background: #0078dc; }
    .uniper .h5p-interactive-video div > .h5p-interaction-button:hover, .uniper .h5p-interactive-video div > .h5p-interaction-button:focus {
      background: #1c264e; }

.h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button,
.h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button,
.h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button,
.h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
  background-color: #72b639; }
  .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus,
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover,
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus,
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover,
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus,
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover,
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
    background-color: #416821; }
  .orange .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button, .orange
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button, .orange
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button, .orange
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
    background-color: #f16f29; }
    .orange .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .orange .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus, .orange
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover, .orange
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus, .orange
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover, .orange
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus, .orange
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover, .orange
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
      background-color: #a9420b; }
  .energy-academy .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button, .energy-academy
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button, .energy-academy
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button, .energy-academy
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
    background-color: #364A9A; }
    .energy-academy .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .energy-academy .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus, .energy-academy
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover, .energy-academy
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus, .energy-academy
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover, .energy-academy
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus, .energy-academy
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover, .energy-academy
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
      background-color: #1c264e; }
  .uniper .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button, .uniper
  .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button, .uniper
  .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button, .uniper
  .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button {
    background: #0078dc; }
    .uniper .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:hover, .uniper .h5p-interactive-video .h5p-text-interaction > .h5p-interaction-button:focus, .uniper
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:hover, .uniper
    .h5p-interactive-video .h5p-table-interaction > .h5p-interaction-button:focus, .uniper
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:hover, .uniper
    .h5p-interactive-video .h5p-link-interaction > .h5p-interaction-button:focus, .uniper
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:hover, .uniper
    .h5p-interactive-video .h5p-image-interaction > .h5p-interaction-button:focus {
      background: #1c264e; }

.h5p-single-choice-set .h5p-joubelui-progressbar-background {
  background-color: #72b639; }
  .orange .h5p-single-choice-set .h5p-joubelui-progressbar-background {
    background-color: #f16f29; }
  .energy-academy .h5p-single-choice-set .h5p-joubelui-progressbar-background {
    background-color: #364A9A; }
  .uniper .h5p-single-choice-set .h5p-joubelui-progressbar-background {
    background: #0078dc; }
    .uniper .h5p-single-choice-set .h5p-joubelui-progressbar-background:hover, .uniper .h5p-single-choice-set .h5p-joubelui-progressbar-background:focus {
      background: #1c264e; }

.h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
  background-color: #72b639; }
  .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
    background-color: #416821; }
  .orange .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
    background-color: #f16f29; }
    .orange .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .orange .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
      background-color: #a9420b; }
  .energy-academy .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
    background-color: #364A9A; }
    .energy-academy .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .energy-academy .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
      background-color: #1c264e; }
  .uniper .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view {
    background: #0078dc; }
    .uniper .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:hover, .uniper .h5p-single-choice-set .h5p-sc-solution-view .h5p-sc-close-solution-view:focus {
      background: #1c264e; }

.h5p-question-feedback {
  color: #72b639; }
  .orange .h5p-question-feedback {
    color: #f16f29; }
  .energy-academy .h5p-question-feedback {
    color: #364A9A; }
  .uniper .h5p-question-feedback {
    background: #0078dc; }

.joubel-simple-rounded-button {
  background-color: #72b639; }
  .joubel-simple-rounded-button:hover, .joubel-simple-rounded-button:focus {
    background-color: #416821; }
  .orange .joubel-simple-rounded-button {
    background-color: #f16f29; }
    .orange .joubel-simple-rounded-button:hover, .orange .joubel-simple-rounded-button:focus {
      background-color: #darken #f16f29, 20%; }
  .energy-academy .joubel-simple-rounded-button {
    background-color: #364A9A; }
    .energy-academy .joubel-simple-rounded-button:hover, .energy-academy .joubel-simple-rounded-button:focus {
      background-color: #darken #364A9A, 20%; }
  .uniper .joubel-simple-rounded-button {
    background: #0078dc; }
    .uniper .joubel-simple-rounded-button:hover, .uniper .joubel-simple-rounded-button:focus {
      background-color: #darken #0078dc, 20%; }
  .joubel-simple-rounded-button:active {
    position: relative;
    background-color: #364A9A;
    -webkit-box-shadow: inset 0 4px 0px #364A9A;
    -moz-box-shadow: inset 0 4px 0px #364A9A;
    box-shadow: inset 0 4px 0px #364A9A; }

.h5p-joubelui-score-bar-progress {
  background: #72b639; }

.form-button-wrapper input, .form-button-wrapper .button-link {
  background-color: #000;
  color: white;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  line-height: 100%;
  padding: 10px 12px; }

.form-button-wrapper input.disabled {
  background-color: #cecece; }

.load-image {
  background-image: url("../images/radio.png"), url("../images/radio_disabled.png"), url("../images/checkbox.png"), url("../images/checkbox_disabled.png");
  display: none; }
  .orange .load-image {
    background-image: url("../images/orange/radio.png"), url("../images/radio_disabled.png"), url("../images/orange/checkbox.png"), url("../images/checkbox_disabled.png"); }
  .energy-academy .load-image {
    background-image: url("../images/energy-academy/radio.png"), url("../images/radio_disabled.png"), url("../images/energy-academy/checkbox.png"), url("../images/checkbox_disabled.png"); }
  .uniper .load-image {
    background-image: url("../images/uniper/radio.png"), url("../images/radio_disabled.png"), url("../images/uniper/checkbox.png"), url("../images/checkbox_disabled.png"); }

#foo:checked,
input.form-checkbox + label::before,
input.form-radio + label::before,
form table input.form-checkbox,
form table input.form-radio {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/radio.png");
  background-repeat: no-repeat;
  background-position: 0 0; }
  .orange #foo:checked, .orange
  input.form-checkbox + label::before, .orange
  input.form-radio + label::before, .orange
  form table input.form-checkbox, .orange
  form table input.form-radio {
    background-image: url("../images/orange/radio.png"); }
  .energy-academy #foo:checked, .energy-academy
  input.form-checkbox + label::before, .energy-academy
  input.form-radio + label::before, .energy-academy
  form table input.form-checkbox, .energy-academy
  form table input.form-radio {
    background-image: url("../images/energy-academy/radio.png"); }
  .uniper #foo:checked, .uniper
  input.form-checkbox + label::before, .uniper
  input.form-radio + label::before, .uniper
  form table input.form-checkbox, .uniper
  form table input.form-radio {
    background-image: url("../images/uniper/radio.png"); }

#foo:checked,
input.form-checkbox:checked + label::before,
input.form-radio:checked + label::before {
  background-position: 0 -20px; }

#foo:checked,
input.form-checkbox.disabled + label::before,
form table input.form-checkbox.disabled {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/checkbox_disabled.png");
  background-repeat: no-repeat;
  background-position: 0 0; }

#foo:checked,
input.form-checkbox + label::before,
form table input.form-checkbox {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/checkbox.png");
  background-repeat: no-repeat;
  background-position: 0 0; }
  .orange #foo:checked, .orange
  input.form-checkbox + label::before, .orange
  form table input.form-checkbox {
    background-image: url("../images/orange/checkbox.png"); }
  .energy-academy #foo:checked, .energy-academy
  input.form-checkbox + label::before, .energy-academy
  form table input.form-checkbox {
    background-image: url("../images/energy-academy/checkbox.png"); }
  .uniper #foo:checked, .uniper
  input.form-checkbox + label::before, .uniper
  form table input.form-checkbox {
    background-image: url("../images/uniper/checkbox.png"); }

#foo:checked,
input.form-checkbox:checked + label::before,
input.form-radio:checked + label::before {
  background-position: 0 -20px; }

#foo:checked,
input.form-radio.disabled + label::before,
form table input.form-radio.disabled {
  height: 20px;
  background-color: transparent;
  background-image: url("../images/radio_disabled.png");
  background-repeat: no-repeat;
  background-position: 0 0; }

#foo:checked,
input.form-checkbox.disabled:checked + label::before,
input.form-radio.disabled:checked + label::before {
  background-position: 0 -20px; }

.form-type-checkbox .feedback {
  margin-left: 76.7px;
  font-size: 14px;
  position: relative;
  padding-right: 60px; }

.form-type-checkbox .feedback .media-button {
  position: absolute;
  right: 0;
  top: 0; }

.form-type-checkbox .feedback .icon-videocam {
  display: block;
  padding: 10px 5px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 30px;
  color: #72b639;
  text-decoration: none;
  border: 1px solid #72b639; }
  .orange .form-type-checkbox .feedback .icon-videocam {
    color: #f16f29;
    border: 1px solid #f16f29; }
  .energy-academy .form-type-checkbox .feedback .icon-videocam {
    color: #364A9A;
    border: 1px solid #364A9A; }
  .uniper .form-type-checkbox .feedback .icon-videocam {
    color: #0078dc;
    border: 1px solid #0078dc; }

.form-type-checkbox .feedback div.need_to_know a:after {
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  background: url(../images/need_to-know.png) no-repeat;
  content: ' ';
  margin-top: -2px;
  margin-bottom: -20px;
  right: -8px;
  top: -3px; }

.form-type-checkbox .feedback .icon-videocam:hover {
  color: #EF2751;
  border-color: #EF2751; }

.form-type-checkbox .feedback .fancybox-opener:before {
  text-indent: 0px; }

.form-item-question-answer label:first-child {
  font-family: 'Nettoweb';
  font-size: 24px;
  margin-bottom: 45px; }

.form-item-question-answer .form-checkboxes {
  padding: 0 20px; }

.form-item-question-answer .form-checkboxes label {
  font-size: 18px;
  font-family: 'Nettoweb'; }

.form-item-question-answer .form-type-radio label,
.form-item-question-answer .form-checkboxes label {
  max-width: 80%; }

#feedback-message {
  color: #EF2751; }

ul.nice-menu-down li {
  margin-left: 10px; }

ul.nice-menu-down [class^="ico-"],
ul.nice-menu-down [class^="icon-"],
ul.nice-menu-down li span.nolink,
#training-index-button,
.block-ys-elearning-custom [class*="icon-"],
.custom-icon[class*="icon-"] {
  color: #fff;
  background: #72b639 !important;
  line-height: 1.7em; }
  .orange ul.nice-menu-down [class^="ico-"], .orange
  ul.nice-menu-down [class^="icon-"], .orange
  ul.nice-menu-down li span.nolink, .orange
  #training-index-button, .orange
  .block-ys-elearning-custom [class*="icon-"], .orange
  .custom-icon[class*="icon-"] {
    background: #f16f29 !important; }
  .energy-academy ul.nice-menu-down [class^="ico-"], .energy-academy
  ul.nice-menu-down [class^="icon-"], .energy-academy
  ul.nice-menu-down li span.nolink, .energy-academy
  #training-index-button, .energy-academy
  .block-ys-elearning-custom [class*="icon-"], .energy-academy
  .custom-icon[class*="icon-"] {
    background: #DF2127 !important; }
  .uniper ul.nice-menu-down [class^="ico-"], .uniper
  ul.nice-menu-down [class^="icon-"], .uniper
  ul.nice-menu-down li span.nolink, .uniper
  #training-index-button, .uniper
  .block-ys-elearning-custom [class*="icon-"], .uniper
  .custom-icon[class*="icon-"] {
    background: #de3a6b !important; }

.block-ys-elearning-custom a.media-button {
  font-size: 16px !important;
  width: 26px !important;
  height: 26px !important; }
  .block-ys-elearning-custom a.media-button:before {
    color: #fff;
    padding-top: 0;
    width: 26px !important;
    height: 26px !important;
    font-size: 16px !important;
    margin-left: 0; }

#block-ys-elearning-custom-training-information .field-name-field-kaltura-video,
.block-ys-elearning-custom .field-name-field-kaltura-video {
  display: inline-block; }

#training-index-button,
.block-ys-elearning-custom .icon-info,
.block-ys-elearning-custom .icon-help {
  display: block;
  text-decoration: none;
  width: 26px !important;
  height: 26px !important;
  overflow: hidden;
  margin: 6px 0px 0px 4px; }

.block-ys-elearning-custom .icon-help {
  font-size: 16px; }

#training-index-button:before,
.block-ys-elearning-custom [class*="icon-"]:before {
  position: relative;
  margin-bottom: 25px;
  top: 1px; }

.block-ys-elearning-custom .icon-info:before,
.block-ys-elearning-custom .icon-help:before {
  position: relative;
  top: 0px;
  margin-bottom: 12px; }

.block-ys-elearning-custom .icon-info,
.block-ys-elearning-custom .icon-help {
  float: right; }

#block-ys-elearning-index-training-index-menu {
  padding: 0; }

ul.nice-menu-down [class^="ico-"]:hover,
ul.nice-menu-down [class^="icon-"]:hover,
ul.nice-menu-down li span.nolink:hover,
ul.nice-menu-down [class^="ico-"]:focus,
ul.nice-menu-down [class^="icon-"]:focus,
ul.nice-menu-down li span.nolink:focus {
  color: #72b639;
  background: #fff;
  border: 1px solid #72b639; }
  .orange ul.nice-menu-down [class^="ico-"]:hover, .orange
  ul.nice-menu-down [class^="icon-"]:hover, .orange
  ul.nice-menu-down li span.nolink:hover, .orange
  ul.nice-menu-down [class^="ico-"]:focus, .orange
  ul.nice-menu-down [class^="icon-"]:focus, .orange
  ul.nice-menu-down li span.nolink:focus {
    color: #f16f29;
    border: 1px solid #f16f29; }
  .energy-academy ul.nice-menu-down [class^="ico-"]:hover, .energy-academy
  ul.nice-menu-down [class^="icon-"]:hover, .energy-academy
  ul.nice-menu-down li span.nolink:hover, .energy-academy
  ul.nice-menu-down [class^="ico-"]:focus, .energy-academy
  ul.nice-menu-down [class^="icon-"]:focus, .energy-academy
  ul.nice-menu-down li span.nolink:focus {
    color: #364A9A;
    border: 1px solid #364A9A; }
  .uniper ul.nice-menu-down [class^="ico-"]:hover, .uniper
  ul.nice-menu-down [class^="icon-"]:hover, .uniper
  ul.nice-menu-down li span.nolink:hover, .uniper
  ul.nice-menu-down [class^="ico-"]:focus, .uniper
  ul.nice-menu-down [class^="icon-"]:focus, .uniper
  ul.nice-menu-down li span.nolink:focus {
    color: #0078dc;
    border: 1px solid #0078dc; }

ul.nice-menu ul a {
  background-color: #cecece;
  color: #000;
  border: 0;
  font-size: 13px;
  font-weight: bold; }

ul.nice-menu ul li {
  border: 0; }

ul.nice-menu ul {
  left: -81px;
  background: url(../images/menu_top.png) no-repeat center top transparent; }

ul.nice-menu a.icon-user {
  padding-top: 1px !important;
  padding-bottom: 3px !important; }

ul.nice-menu a.icon-book {
  font-size: 14px;
  padding-top: 3px !important;
  padding-bottom: 1px !important;
  padding-left: 0px !important;
  padding-right: 5px !important; }

.tooltipster-base .link-thumb img {
  max-width: 50px;
  opacity: .7;
  filter: alpha(opacity=70); }

.tooltipster-base .link-thumb:hover img {
  opacity: 1;
  filter: alpha(opacity=100); }

div.info-icon-wrapper a {
  overflow: hidden;
  padding-top: 12px; }

.block-ys-elearning-index .content {
  float: right; }

#fancybox-content #information-area {
  padding: 10px; }

#information-area img {
  width: 100%; }

#information-block {
  padding-top: 20px; }

#fancybox-content #information-block {
  overflow: hidden; }

body.fancybox {
  padding: 20px;
  overflow-x: hidden; }

.tipsy-inner img {
  width: 100%;
  margin-bottom: 10px; }

.tipsy-inner {
  max-width: none !important; }

.tipsy-inner a,
body .tooltipster-default a {
  color: #fff;
  text-decoration: underline !important; }

li.tipsy-title {
  max-width: 500px; }

.tooltipster-default,
.tooltipster-default a {
  background: #fff;
  border: none;
  color: #fff;
  font-size: 1em !important;
  line-height: 1.5em !important;
  text-decoration: none !important; }

.tooltipster-content .jwplayer-video {
  height: 54px;
  width: auto; }

.tooltipster-default a:hover {
  text-decoration: underline; }

.tooltipster-content ul {
  list-style: disc;
  padding: 1em; }

.tooltipster-content .field-item span.need_to_know {
  padding-right: 18px;
  background: url(../images/ntn_file.png) no-repeat right center; }

.tooltipster-base .tooltipster-box {
  background: #fff;
  border: 2px solid #72b639; }
  .orange .tooltipster-base .tooltipster-box {
    border: 2px solid #f16f29; }
  .energy-academy .tooltipster-base .tooltipster-box {
    border: 2px solid #364A9A; }

.tooltipster-base .tooltipster-box .tooltipster-content {
  color: #000; }

.tooltipster-base.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #72b639; }
  .orange .tooltipster-base.tooltipster-top .tooltipster-arrow-border {
    border-top-color: #f16f29; }
  .energy-academy .tooltipster-base.tooltipster-top .tooltipster-arrow-border {
    border-top-color: #364A9A; }

.tooltipster-base.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #72b639; }
  .orange .tooltipster-base.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: #f16f29; }
  .energy-academy .tooltipster-base.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: #364A9A; }

.tooltipster-base.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #72b639; }
  .orange .tooltipster-base.tooltipster-left .tooltipster-arrow-border {
    border-left-color: #f16f29; }
  .energy-academy .tooltipster-base.tooltipster-left .tooltipster-arrow-border {
    border-left-color: #364A9A; }

.tooltipster-base.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #72b639; }
  .orange .tooltipster-base.tooltipster-right .tooltipster-arrow-border {
    border-right-color: #f16f29; }
  .energy-academy .tooltipster-base.tooltipster-right .tooltipster-arrow-border {
    border-right-color: #364A9A; }

.tooltipster-base.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #fff; }

.tooltipster-base.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #fff; }

.tooltipster-base.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #fff; }

.tooltipster-base.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #fff; }

.tooltipster-base.theme-title .tooltipster-box {
  background: #000;
  border: 2px solid #000; }

.tooltipster-base.theme-title .tooltipster-box .tooltipster-content {
  color: #fff; }

.tooltipster-base.theme-title.tooltipster-top .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #000; }

.tooltipster-base.theme-title.tooltipster-bottom .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #000; }

.tooltipster-base.theme-title.tooltipster-left .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #000; }

.tooltipster-base.theme-title.tooltipster-right .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #000; }

.custom-tooltip {
  border: 1px solid #72b639;
  color: #2c2c2c;
  background-color: white;
  padding-right: 20px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  position: relative;
  font-size: 16px;
  line-height: 16px;
  padding: 20px;
  font-family: 'Nettoweb'; }
  .orange .custom-tooltip {
    border: 1px solid #f16f29; }
  .energy-academy .custom-tooltip {
    border: 1px solid #364A9A; }

.custom-tooltip .arrow {
  display: block;
  height: 25px;
  width: 15px;
  background: url("../images/custom-tooltip.png") no-repeat right center #fff;
  position: absolute;
  right: -15px;
  top: 169px; }

.custom-tooltip .jwplayer-video {
  height: 350px; }

.draw-feedback {
  background: #f5f5f9;
  padding: 0 20px;
  margin: 20px 0;
  border-top: 2px solid #3a5c21;
  border-bottom: 2px solid #3a5c21; }
  .orange .draw-feedback {
    border-top: 2px solid #7d3108;
    border-bottom: 2px solid #7d3108; }
  .energy-academy .draw-feedback {
    border-top: 2px solid #293874;
    border-bottom: 2px solid #293874; }

.ontassen .tooltipster-default {
  border-radius: 5px;
  border: 2px solid #3a5c21;
  background: #f5f5f9;
  color: #000; }
  .orange .ontassen .tooltipster-default {
    border: 2px solid #7d3108; }
  .energy-academy .ontassen .tooltipster-default {
    border: 2px solid #293874; }

.ontassen .tooltipster-default a {
  background: none;
  color: #000; }

.tooltipster-content #custom_video_button {
  text-decoration: none !important; }

body.fancybox.page-ys-giga-image-viewer {
  padding: 0;
  margin: 0; }

#block-system-main .inline-icon {
  color: #72b639; }
  .orange #block-system-main .inline-icon {
    color: #f16f29; }
  .energy-academy #block-system-main .inline-icon {
    color: #364A9A; }

#block-system-main .inline-icon:before {
  color: #72b639;
  border-color: #72b639; }
  .orange #block-system-main .inline-icon:before {
    color: #f16f29;
    border-color: #f16f29; }
  .energy-academy #block-system-main .inline-icon:before {
    color: #364A9A;
    border-color: #364A9A; }

.energy-academy .block-ys-elearning-index .menu_arrow {
  background-image: url(../images/energy-academy/arrow.png);
  background-repeat: no-repeat;
  width: 36px;
  height: 27px;
  bottom: 30px;
  left: 348px; }

.energy-academy .block-ys-elearning-index .tab-wrapper {
  background: #364A9A; }

.energy-academy .block-ys-elearning-index .tab-content {
  background: #fff; }

.energy-academy .block-ys-elearning-index #tabs {
  border-bottom: none !important; }
  .energy-academy .block-ys-elearning-index #tabs ul {
    background: #fff; }
  .energy-academy .block-ys-elearning-index #tabs li {
    background: #fff;
    border-bottom: 1px solid #364A9A;
    color: #000;
    border-top: none;
    border-left: none !important;
    border-right: none !important; }
    .energy-academy .block-ys-elearning-index #tabs li.ui-state-active {
      border-top: 0;
      border-bottom: 1px solid #DF2127 !important; }
    .energy-academy .block-ys-elearning-index #tabs li a {
      color: #000;
      text-shadow: none; }

.energy-academy .block-ys-elearning-index .filterform {
  border-bottom: none; }

.energy-academy .block-ys-elearning-index .filterinput {
  background: #f5f5f5;
  box-shadow: none;
  text-shadow: none;
  color: #000; }

.energy-academy .block-ys-elearning-index h2 {
  color: #364A9A; }

.energy-academy .block-ys-elearning-index .tab-content-list {
  background: #fff;
  color: #000; }
  .energy-academy .block-ys-elearning-index .tab-content-list ul li a {
    color: #000; }
    .energy-academy .block-ys-elearning-index .tab-content-list ul li a:hover, .energy-academy .block-ys-elearning-index .tab-content-list ul li a:focus {
      background: #fff;
      color: #364A9A; }

.energy-academy .block-ys-elearning-index #training-definitions .tab-content-list li.first h2,
.energy-academy .block-ys-elearning-index #training-index .tab-content-list li.first h2 {
  border-top: none;
  border-bottom: 1px solid #2c2c2c; }

.energy-academy .block-ys-elearning-index #training-definitions .tab-content-list li dfn,
.energy-academy .block-ys-elearning-index #training-definitions .tab-content-list li a,
.energy-academy .block-ys-elearning-index #training-index .tab-content-list li dfn,
.energy-academy .block-ys-elearning-index #training-index .tab-content-list li a {
  border-top: none;
  border-bottom: 1px solid #2c2c2c; }
