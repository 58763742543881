.tooltipster-base .link-thumb img {
  max-width: 50px;
  opacity: .7;
  filter: alpha(opacity=70);
}
.tooltipster-base .link-thumb:hover img {
  opacity: 1;
  filter: alpha(opacity=100);
}
div.info-icon-wrapper a {
  overflow: hidden;
  padding-top: 12px;
}
.block-ys-elearning-index .content {
  float: right;
}
#fancybox-content #information-area {
  padding: 10px;
}
#information-area img {
  width: 100%;
}
#information-block {
  padding-top: 20px;
}
#fancybox-content #information-block {
  overflow: hidden;
}
body.fancybox {
  padding: 20px;
  overflow-x: hidden;
}

.tipsy-inner img {
  width: 100%;
  margin-bottom: 10px;
}
.tipsy-inner {
  max-width: none !important;
}
.tipsy-inner a,
body .tooltipster-default a {
  color: $white;
  text-decoration: underline !important;
}
li.tipsy-title {
  max-width: 500px;
}

.tooltipster-default,
.tooltipster-default a {
  background: $white;
  border: none;
  color: $white;
  font-size: 1em !important;
  line-height: 1.5em !important;
  text-decoration: none !important;
}
.tooltipster-content .jwplayer-video {
  height: 54px;
  width: auto;
}
.tooltipster-default a:hover {
  text-decoration: underline;
}
.tooltipster-content ul {
  list-style: disc;
  padding: 1em;
}
.tooltipster-content .field-item span.need_to_know {
  padding-right: 18px;
  background: url(../images/ntn_file.png) no-repeat right center;
}

.tooltipster-base .tooltipster-box {
  background: $white;
  border: 2px solid $oof-green;

  .orange & {
    border: 2px solid $oof-orange;
  }
  .energy-academy & {
    border: 2px solid $ea-blue;
  }
}
.tooltipster-base .tooltipster-box .tooltipster-content {
  color: $black;
}

.tooltipster-base.tooltipster-top .tooltipster-arrow-border {
  border-top-color: $oof-green;

  .orange & {
    border-top-color: $oof-orange;
  }
  .energy-academy & {
    border-top-color: $ea-blue;
  }
}
.tooltipster-base.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: $oof-green;

  .orange & {
    border-bottom-color: $oof-orange;
  }
  .energy-academy & {
    border-bottom-color: $ea-blue;
  }
}
.tooltipster-base.tooltipster-left .tooltipster-arrow-border {
  border-left-color: $oof-green;

  .orange & {
    border-left-color: $oof-orange;
  }
  .energy-academy & {
    border-left-color: $ea-blue;
  }
}
.tooltipster-base.tooltipster-right .tooltipster-arrow-border {
  border-right-color: $oof-green;

  .orange & {
    border-right-color: $oof-orange;
  }
  .energy-academy & {
    border-right-color: $ea-blue;
  }
}

.tooltipster-base.tooltipster-top .tooltipster-arrow-background {
  border-top-color: $white;
}
.tooltipster-base.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: $white;
}
.tooltipster-base.tooltipster-left .tooltipster-arrow-background {
  border-left-color: $white;
}
.tooltipster-base.tooltipster-right .tooltipster-arrow-background {
  border-right-color: $white;
}

.tooltipster-base.theme-title .tooltipster-box {
  background: $black;
  border: 2px solid $black;
}
.tooltipster-base.theme-title .tooltipster-box .tooltipster-content {
  color: $white;
}
.tooltipster-base.theme-title.tooltipster-top .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-top .tooltipster-arrow-background {
  border-top-color: $black;
}
.tooltipster-base.theme-title.tooltipster-bottom .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: $black;
}
.tooltipster-base.theme-title.tooltipster-left .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-left .tooltipster-arrow-background {
  border-left-color: $black;
}
.tooltipster-base.theme-title.tooltipster-right .tooltipster-arrow-border,
.tooltipster-base.theme-title.tooltipster-right .tooltipster-arrow-background {
  border-right-color: $black;
}

.custom-tooltip {
  border: 1px solid $oof-green;
  color: $gray-900;
  background-color: white;
  padding-right: 20px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  position: relative;
  font-size: 16px;
  line-height: 16px;
  padding: 20px;
  font-family: 'Nettoweb';

  .orange & {
    border: 1px solid $oof-orange;
  }
  .energy-academy & {
    border: 1px solid $ea-blue;
  }
}
.custom-tooltip .arrow {
  display: block;
  height: 25px;
  width: 15px;
  background: url('../images/custom-tooltip.png') no-repeat right center $white;
  position: absolute;
  right: -15px;
  top: 169px;
}
.custom-tooltip .jwplayer-video {
  height: 350px;
}

.draw-feedback {
  background: $gray-100;
  padding: 0 20px;
  margin: 20px 0;
  border-top: 2px solid darken($oof-dark-green, 10%);
  border-bottom: 2px solid darken($oof-dark-green, 10%);

  .orange & {
    border-top: 2px solid darken($oof-dark-orange, 10%);
    border-bottom: 2px solid darken($oof-dark-orange, 10%);
  }
  .energy-academy & {
    border-top: 2px solid darken($ea-blue, 10%);
    border-bottom: 2px solid darken($ea-blue, 10%);
  }
}

.ontassen .tooltipster-default {
  border-radius: 5px;
  border: 2px solid darken($oof-dark-green, 10%);
  background: $gray-100;
  color: $black;

  .orange & {
    border: 2px solid darken($oof-dark-orange, 10%);
  }
  .energy-academy & {
    border: 2px solid darken($ea-blue, 10%);
  }
}
.ontassen .tooltipster-default a {
  background: none;
  color: $black;
}
.tooltipster-content #custom_video_button {
  text-decoration: none !important;
}
body.fancybox.page-ys-giga-image-viewer {
  padding: 0;
  margin: 0;
}
#block-system-main .inline-icon {
  color: $oof-green;

  .orange & {
    color: $oof-orange;
  }
  .energy-academy & {
    color: $ea-blue;
  }
}
#block-system-main .inline-icon:before {
  color: $oof-green;
  border-color: $oof-green;

  .orange & {
    color: $oof-orange;
    border-color: $oof-orange;
  }
  .energy-academy & {
    color: $ea-blue;
    border-color: $ea-blue;
  }
}
